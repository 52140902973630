.appointment_text {
  font-family: 'Inter';
  font-style: normal !important;
  font-weight: 600;
  font-size: 15px;
  line-height: 100%;
  color: #898a8d;
  margin: 0;
}

.appointment {
  &__container {
    // padding: 1rem;
    position: relative;
    width: 90%;
    margin: 0.6rem auto;
    background: $secondary-color;
    border: 1px solid #e8f3f1;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.14);
    border-radius: 11.1321px;
    color: #101623;

    &--active {
      border: 1px solid #199a8e;
      box-shadow: 0px 0px 10px #199a8e;
    }
  }

  &__container:hover {
    border: 1px solid #199a8e;
    box-shadow: 0px 0px 10px #199a8e;
  }

  &__top {
    display: grid;
    column-gap: 1rem;
    padding: 1rem;
    grid-template-columns: 1fr 4fr;
  }

  &__doc {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 0.4rem;
    color: $primary-text-color;
    &__name {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
    }

    &__spc {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 160%;
    }

    &__exp {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
    }
  }

  &__amount {
    display: flex;
    align-items: start;
    justify-content: end;
    position: absolute;
    top: 15px;
    right: 10px;
  }

  &__bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    background-color: $primary-color;
    margin-top: 0.5rem !important;
    // padding: 6px 20px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    color: #ffc300;
    line-height: 12px;
    width: 100%;
    border-radius: 0 0 11px 11px;
  }

  &__charges {
    background: #fea300;
    display: flex;
    justify-content: center;
    width: fit-content;
    color: #ffffff;
    border-radius: 8px 0px;
    > p {
      font-family: 'Inter';
      font-style: normal;
      margin: 0.4rem;
      font-weight: 700;
      font-size: 11px;
      line-height: 12px;
    }
  }

  &__avatar {
    > div {
      width: 74px !important;
      height: 74px !important;
    }
  }

  &__book {
    position: absolute;
    top: 75vh;
    width: 100%;

    &_button {
      margin: auto;
      text-transform: capitalize;
      width: 330px;
      font-size: 16px;
      padding: 1.2rem 10px;
    }
  }
  

  &__patient {
    width: 100%;
    margin-top: -10px;

    h2{
      border: none;
      display: flex;
      align-items: center;
      color: #101623;
      font-size: large;
      button{
        background: none;
        border: none;
      }
    }


    h6 {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 17px;
      text-align: center;
      width: 100%;
      margin: 0;
      text-align: center;
      color: #101623;
      margin-top: 46px;
    }

    &__details {
      display: flex;
      align-items: center;
      margin-left: 1rem;
      position: relative;
      max-width: max-content;
      padding-left: 140px;
      // .patient_atc{
      //   display: flex;
      //   align-items: center;
      //   justify-content: center;
      // }
      

      &__scroll {
        display: flex;
        justify-content: start;
        align-items: center;
        max-width: 250px;
        padding: 0 0.4rem;
        overflow-x: scroll;
        margin: 1rem 0 2rem 0;
        gap: 0.5rem;
        &::-webkit-scrollbar {
          display: none;
        }
        -ms-overflow-style: none; /* Internet Explorer 10+ */
        scrollbar-width: none; /* Firefox */
      }
    }

    &__add {
      position: absolute;
      right: -0.5rem;
      // top: 1rem;
      max-width: 94%;
    }

    &__button {
      background: #e8f3f1;
      border: 0.5px solid #199a8e;
      border-radius: 50px;
      // width: fit-content;
      font-family: 'Inter';
      min-width: fit-content;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      text-align: center;
      padding: 0.5rem 1rem;
      color: #000;

      display: block;
      position: relative;
      width: 100%;
      padding: 5px; 
      max-height: 50%;
      border-radius: 10px;
      margin-top: 15px;
      // color: #2096b3;
      box-shadow: 0 2px 10px #888;
  

      &__selected {
        color: #fff !important;
        background: #199a8e !important;
        max-width: 100%;
      }
    }
  }

  &__search {
    width: 84%;
    margin: auto;
    border: 1px solid #f4f4f4;
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 50px;

    input {
      outline: none;
      width: 100%;
      border: none;
      filter: none;

      &::placeholder {
        color: #898a8d;
      }

      &:-ms-input-placeholder {
        color: #898a8d;
      }

      &::-ms-input-placeholder {
        color: #898a8d;
      }
    }
  }

  &__cards {
    height: 60vh;
    overflow-y: scroll;
  }
}

.appointment__screen {
  position: relative;
  width: 100%;
  margin-top: 1rem;

  &__user {
    justify-content: center !important;
    gap: 0.5rem;
    margin: 1rem 0 2rem 50px !important;

    .center-align{
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__exit {
    font-weight: 600;
    font-size: 18px;
    margin: 1rem 0;
    color: red;
    text-align: center;
    &__buttons {
      button {
        padding: 10px 8px;
        width: 30%;
      }
      display: flex;
      gap: 1rem;
    }
  }

  &__time {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #101623;
    margin-bottom: 1rem;
  }

  &__close {
    position: absolute;
    right: 10px;
    top: 0;
  }

  &__back {
    position: absolute;
    top: 0;
    left: 10px;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    color: #101623;
  }

  &__type {
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0;

    label {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      margin-top: 0.4rem;
    }

    &__label {
      &__green {
        width: 80%;
        margin: auto;
        background-color: #d6ece8;
        color: #199a8e;
        padding: 1rem 1rem;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 1rem;
      }

      &__red {
        width: 80%;
        margin: auto;
        background-color: #ffe8cc;
        color: #a2000b;
        padding: 1rem 1rem;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 1rem;

      }
    }

    &__button {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 15px;
      text-align: center;
      color: #fff;
      min-width: 200px;
      padding: 1rem 0;
      width: 90%;
      border-radius: 10px;
    }
  }

  &__meetnow {
    background: #199a8e;
  }

  &__schedule {
    background: #199a8e;
    margin-top: 1rem;
  }
  &__followup {
    background: #199a8e;
  }

  &__book {
    width: 90%;
    margin: 1.5rem auto 1.5rem auto;
    &__type {
      &__text {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 19px;
        text-align: start;
        color: #000000;
      }

      &__box {
        display: flex;
        margin-top: 1rem;
        justify-content: start;
        gap: 1rem;
        align-items: center;
        div {
          color: #199a8e;
          font-size: 11px;
          border: none;
          font-weight: 600;
        }
      }
    }
    &__date {
      margin-top: 1.5rem;
      &__input {
        margin-top: 1rem;
        position: relative;
        background: #e8f3f1;
        width: 60%;
        border-radius: 5px;

        img {
          position: absolute;
          right: 0.1rem;
          width: 39px;
          height: 40px;
        }

        label {
          position: absolute;
          top: 10px;
          left: 1rem;
          font-family: 'Inter';
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          line-height: 19px;
          text-align: start;
          color: #000000;
        }

        input {
          position: relative;
          padding: 0 !important;
          border: none !important;
          height: 40px;
          width: 100%;
          outline: none;
          background: transparent;
          font-family: 'Inter';
          font-style: normal;
          font-size: 16px;
          line-height: 100%;

          -webkit-tap-highlight-color: transparent;

          &::-webkit-datetime-edit-text {
            -webkit-appearance: none;
            display: none;
          }

          &::-webkit-datetime-edit-month-field {
            -webkit-appearance: none;
            display: none;
          }

          &::-webkit-datetime-edit-day-field {
            -webkit-appearance: none;
            display: none;
          }

          &::-webkit-datetime-edit-year-field {
            -webkit-appearance: none;
            display: none;
          }

          &::-webkit-calendar-picker-indicator {
            background-position: right;
            background-size: auto;
            cursor: pointer;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            top: 10px;
            width: auto;
          }
        }
      }
    }

    &__time {
      margin-top: 1.5rem;
      &__wrapper {
        display: flex;
        gap: 0.8rem;
        margin: 1rem 0;
        align-items: center;
      }
      &__icon {
        position: relative;
        z-index: 2;
        svg:nth-child(2) {
          position: absolute;
          z-index: 1;
          left: 1rem;
          width: 1rem !important;
          height: 1rem !important;
        }
      }
      &__title {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 15px;
        color: #000000;
      }
    }

    &__button {
      width: 100%;
      button {
        width: -moz-available; /* WebKit-based browsers will ignore this. */
        width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
        width: fill-available;
        margin: 1.5rem auto;
      }
    }
  }

  &__payment {
    width: 90%;
    margin: 1.4rem auto;
    &__heading {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 15px;
      text-align: center;
      color: #101623;
    }

    &__subHeading {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 15px;
      text-align: center;
      width: 80%;
      margin: auto;
      margin-top: 2rem !important;
      color: #898a8d;
    }

    &__ways {
      display: flex;
      justify-content: center;
      gap: 1rem;
      margin: 1.4rem 0;
    }
    &__card {
      display: flex;
      padding: 0.6rem 0;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      &:nth-child(1) {
        width: 150px;
        height: 158px;
        background: #7879f1;
        border-radius: 10px;
        button {
          background-color: #5d5fef;
        }
      }

      &:nth-child(2) {
        width: 150px;
        height: 158px;
        background: #f178b6;
        border-radius: 10px;
        button {
          background-color: #c4377f;
        }
      }
      button {
        border-radius: 5px;
        padding: 8px 10px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        text-transform: unset;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        color: #ffffff;
        width: 100%;
      }
    }
    &__button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 86%;
    }

    &__discount {
      font-family: 'Inter';
      font-style: normal;
      text-align: center;
      color: #ffffff;
      strong {
        font-weight: 600;
        font-size: 18px;
        line-height: 15px;
        display: block;
      }

      p {
        margin: 0.2rem 0;
        font-weight: 400;
        font-size: 12px;
      }
    }
  }

  &__checkout {
    width: 90%;
    margin: 2rem auto;
    font-family: 'Inter';
    font-style: normal;

    &__bottom {
      margin: 1rem 0;
      &__text {
        text-align: start;
        font-weight: 500;
        font-size: 10px;
        color: #898a8d;
        margin-bottom: 1rem;
      }

      button {
        margin: auto;
      }
    }

    &__payment {
      margin: 1rem 0;
      div {
        color: #000;
        font-weight: 800;
        span {
          text-decoration: unset !important;
          span {
            margin-left: 0.4rem;
          }
          div {
            font-weight: 500;
            font-size: 12px;
            color: #898a8d;
            margin-top: 0.2rem;
          }
        }
      }
    }
  }
  &__details {
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    text-align: start;
  }

  &__table {
    padding: 1.4rem 0;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    border-bottom: 1px solid #199a8e;
    &__row {
      display: flex;
      justify-content: space-between;
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      color: #898a8d;

      span:nth-child(1) {
        text-decoration: line-through;
      }

      strong {
        color: red;
        margin-left: 0.4rem;
      }

      span:nth-child(2) {
        color: #199a8e;
        font-weight: 600;
        margin-left: 0.4rem;
      }
    }
  }

  &__verify {
    width: 90%;
    margin: 1rem auto;
    font-family: 'Inter';
    font-style: normal;
    &_button {
      background-color: #fff;
      border: 1px solid $primary-color;
      color: $primary-color;
      font-weight: 700;
      width: 50%;
    }

    &__image {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 1rem auto;
      img {
        width: 200px;
        height: 130px;
      }
    }

    &__text:nth-child(2) {
      text-align: center;
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      color: #101623;
    }

    &__text:nth-child(3) {
      text-align: center;
      margin: 1rem auto;
      font-size: 14px;
      line-height: 18px;
      font-weight: 600;
      color: #199a8e;
    }
  }
}
